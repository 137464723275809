import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { TABLA, TCLASIFICACION_SERVICIO_CONTRATADO, TFICHERO } from '@/utils/consts'
import { currentDate } from '@/utils/date'

export default {
  async selectSistema (Vue, idsistema, idtecnico) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idsistema', idsistema)
    if (idtecnico) {
      apiFilter.addExact('idtecnico', idtecnico)
    }
    const resp = await Vue.$api.call('sistema.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectDetails (Vue, idsistema, idcliente, esTecnico, esComercial, esVigilante) {
    let apiCalls = []
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idsistema', idsistema)
      .addGT('estado', 0)
    apiCalls.push({ name: 'sistemaTviacomunicacionCount', method: 'sistemaTviacomunicacion.select', params: { filter: apiFilter, wrapper: 'count' } })
    apiCalls.push({ name: 'selectDatoSistemaCount', method: 'datoSistema.select', params: { filter: apiFilter, wrapper: 'count' } })
    const apiFilterMaterialSistema = new APIFilter()
    apiFilterMaterialSistema
      .addExact('idsistema', idsistema)
      .addGT('estado', 0)
      .addIsNull('fdesinstalacion', false)
    apiCalls.push({ name: 'selectMaterialSistema', method: 'materialSistema.select', params: { filter: apiFilterMaterialSistema, wrapper: 'count' } })
    const apiFilterSistemaMant = new APIFilter()
    apiFilterSistemaMant
      .addExact('idsistema', idsistema)
      .addGT('estado', 0)
      .addExact('activar', true)
    apiCalls.push({ name: 'sistemaMantCount', method: 'sistemaMant.select', params: { filter: apiFilterSistemaMant, wrapper: 'count' } })
    apiCalls.push(Vue.$online.fichero.ApiCallSelectCount(idsistema, TABLA.sistema.idtabla, esTecnico, esComercial, esVigilante))
    const apiFilterCRM = new APIFilter()
      .addExact('idsistema', idsistema)
    apiCalls.push({ name: 'selectOperacioncrmCount', method: 'operacioncrm.select', params: { filter: apiFilterCRM, wrapper: 'count' } })
    // servicios contratados
    const apiFilterFechas = new APIFilter()
    apiFilterFechas
      .setOperator(API_FILTER_OPERATORS.OR)
      .addGT('ffin_cuota', currentDate())
      .addIsNull('ffin_cuota', false)
    const apiFilterCuotas = new APIFilter()
    apiFilterCuotas
      .addExact('idsistema', idsistema)
      .addExact('idtclasificacion_servicio', TCLASIFICACION_SERVICIO_CONTRATADO.clasificacion.mantenimiento)
      .addGT('estado', 0)
      .addNestedFilter(
        apiFilterFechas
      )
    // plano
    const apiFilterPlano = Vue.$online.fichero.buildApiFilterVisualizacion(idsistema, TABLA.sistema.idtabla, esTecnico, esComercial, esVigilante)
    apiFilterPlano.addExact('idclasificacion_tfichero', TFICHERO.clasificacion.planos)
    apiCalls.push({ name: 'selectFicheroPlano', method: 'fichero.select', params: { filter: apiFilterPlano, wrapper: 'count' },})
    apiCalls.push({ name: 'selectSistemaCuotaCount', method: 'sistemaCuota.select', params: { filter: apiFilterCuotas, wrapper: 'count' } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async calcRiesgoDisponible (Vue, idcliente) {
    const resp = await Vue.$api.call('cliente.calcRiesgoDisponible', { idcliente })
    return resp.data.result
  },
}
